<template>
  <div>
    <page-loading></page-loading>
  </div>
</template>
<script>
import { computed, onMounted, reactive } from "vue";
import PageLoading from "../../components/console/loadings/PageLoading.vue";
import { useRoute, useRouter } from "vue-router";
import sns from "@/helper/sns";

export default {
  name: "SocialCallBack",
  components: { PageLoading },
  props: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      code: null,
      redirectUri: route.path,
      authData: null,
      provider: computed(() => {
        return state.redirectUri.split("/").pop();
      }),
    });

    onMounted(async () => {
      const authCode = route.query.code;
      state.code = authCode;

      if (state.provider === "kakao") {
        await sns.kakaoInit();
        await sns.kakaoAccessToken(route.query.code);
        await sns.loginToKakao();
      } else {
        router.go(-1);
      }
    });

    return { state };
  },
};
</script>
