<template>
  <div class="stage-container">
    <div class="meeting-loading">
      <div
        v-if="state.isProcessing"
        class="spinner-border text-info"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div>{{ state.message }}</div>
      <div v-if="!state.isProcessing">
        <a href="https://www.bigc.im/"> 빅크로 이동 </a>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/api";

export default {
  name: "SSO",
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentUser = computed(() => {
      return store.getters["auth/user"];
    });
    const state = reactive({
      isDebug: false,
      sso: {
        code: null,
        redirect: null,
      },
      isProcessing: true,
      message: "인증 처리중",
    });

    onMounted(async () => {
      await store.dispatch("auth/logout");
      state.sso.code = route.query?.code;
      state.sso.redirect = route.query?.redirect;
      await getAuthSsoToken();
    });

    const getAuthSsoToken = async () => {
      const payload = {
        code: state.sso.code,
        redirect: state.sso.redirect,
      };
      await ApiService.postAuthSsoToken(payload)
        .then(async (response) => {
          const result = response.data.data;
          await store.dispatch("auth/saveToken", {
            token: result.oauth.accessToken,
          });
          await store.dispatch("auth/fetchUser").then(() => {
            window.location.href = state.sso.redirect;
          });
        })
        .catch((e) => {
          state.isProcessing = false;
          state.message = "인증정보가 만료되었습니다.";
          console.log(e);
        });
    };

    return { state, currentUser };
  },
};
</script>
